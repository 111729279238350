import React from "react";
import { VictoryLabel, VictoryPie } from "victory";
import { graphLabel } from "./TotalBookGraph";
import Strings from "../../../configs/Strings";

export default function PieGraphSmall({
  data,
  loanQty,
}: {
  data: graphLabel[];
  loanQty: number;
}) {
  const SIZE = 200;
  return (
    <section className="relative h-full w-full  text-4xl">
      <svg viewBox="0 0 200 200">
        <VictoryPie
          labelPosition="centroid"
          standalone={false}
          width={SIZE}
          height={SIZE}
          // labelRadius={85}
          labelRadius={58}
          style={{
            labels: {
              fill: "white",
              fontFamily: "BMJUA_ttf",
              // fontSize: 6.5,
              fontSize: 4.5,
            },
          }}
          padAngle={2.5}
          cornerRadius={3}
          // innerRadius={50}
          innerRadius={35}
          animate={{
            duration: 1000,
            easing: "linear",
          }}
          data={data}
          colorScale={[
            "#c13635",
            "#14d9e9",
            "#00b193",
            "#dd8521",
            "#f8d206",
            "#1269df",
            "#b1396b",
            "#a4c21f",
            "#682179",
            "#009d49",
          ]}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          // x={125}
          // y={115}
          x={100}
          y={95}
          style={[
            {
              fontSize: 13,
              // fontSize: 17,
              fill: "white",
              fontFamily: "BMJUA_ttf",
            },
          ]}
          text={Strings.PAGE.RECOMMEND.LOAN_QTY}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          inline
          // x={125}
          // y={136}
          x={100}
          y={110}
          lineHeight={1.2}
          style={[
            { fontSize: 13, fill: "yellow", fontFamily: "BMJUA_ttf" },
            {
              fontSize: 13,
              fill: "white",
              fontFamily: "BMJUA_ttf",
            },
          ]}
          text={[`${loanQty}`, " 권"]}
        />
      </svg>
    </section>
  );
}
