import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Strings from "../configs/Strings";

import { LoginForm } from "../components/auth/LoginForm";

import "./page.styled.css";
import { resetBook } from "../slices/bookDetailSlice";
import { useAppDispatch } from "../hooks";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetBook());
    sessionStorage.clear();
  }, []);

  useEffect(() => {}, [loading]);

  return (
    <section className="w-full h-full flex items-center justify-center ">
      <div
        className={classNames(
          "login-border",
          "flex items-center justify-center ",
          "uhd:w-[800px] uhd:h-[800px]",
          "qhd:w-[600px] qhd:h-[600px]",
          "w-[490px] h-[490px]",
          "tablet:w-100 tablet:h-100",
          "mobile:w-76 mobile:h-76",
          `${loading && "blur"}`,
        )}
      >
         <div
          className={classNames(
            "flex flex-col items-center justify-between" ,
            "pt-7 pb-3 w-8/12 h-72 ",
            "uhd:h-full uhd:justify-center",
            "qhd:h-96 qhd:pt-14",
            "tablet:justify-evenly",
            "mobile:h-3/4", 
          )}
        >
          <h1 className="uhd:text-6xl  qhd:text-5xl text-4xl tracking-wide tablet:text-3xl mobile:text-2xl mobile:mt-6">
            {Strings.PAGE.LOGIN.TITLE}
          </h1>
          <LoginForm setLoading={setLoading} />
          <button
            className={classNames(
              "flex items-center justify-center text-center",
              "login-btn-shadow text-lg rounded-full bg-white  text-loginBtn-blue",
              "py-1 px-4 w-28",
              
              "tablet:text-btn-xs tablet:w-20  tablet:h-7 tablet:mb-5 ",
              "mobile:text-btn-xs mobile:w-20  mobile:h-7 mobile:mb-5 ",
              "qhd:text-xl qhd:w-32 qhd:py-1.5" ,
              "uhd:text-2xl uhd:w-36 ",
            
   
            )}
            type="submit"
            form="loginform"
          >
            {Strings.PAGE.LOGIN.LOGIN}
          </button> 

        </div> 
      </div>
    </section>
  );
};

export default LoginPage;

