import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GenreBookType, LoanHistoryType } from '../types/Books';
import { UserInfoType } from '../types/User';

import { getUserAndBooks } from '../network/user.api';
import String from '../configs/Strings';
import { Library_code } from '../types/Library';

export const PRB = 'personalRecommendBookSlice';

export type PrbState = {
  userInfo: UserInfoType;
  loanHistory: LoanHistoryType;
  genreBooks: GenreBookType;
  failedLogin: string;
  library: Library_code;
};

type PayloadType = {
  [PRB]: PrbState;
};

export const fetchUserAndBooks = createAsyncThunk(
  `${PRB}/fetchUserAndBooks`,
  getUserAndBooks
);

const personalRecommendBookSlice = createSlice({
  name: `${PRB}`,
  initialState: {
    userInfo: { userName: '', age: -1 },
    loanHistory: { favoriteGenre: {}, totalLoanQuantity: -1 },
    genreBooks: {
      총류: [],
      철학: [],
      종교: [],
      사회과학: [],
      자연과학: [],
      기술과학: [],
      예술: [],
      언어: [],
      문학: [],
      역사: [],
    },
    failedLogin: '',
    library: '',
  } as PrbState,
  reducers: {
    resetUser: (state) => {
      state.userInfo = { userName: '', age: -1 };
      state.loanHistory = { favoriteGenre: {}, totalLoanQuantity: -1 };
      state.genreBooks = {
        총류: [],
        철학: [],
        종교: [],
        사회과학: [],
        자연과학: [],
        기술과학: [],
        예술: [],
        언어: [],
        문학: [],
        역사: [],
      };
      state.failedLogin = '';
      state.library = '';
    },
    saveSessionValue: (state, action) => {
      const { genreBooks, loanHistory, userInfo } = action.payload;

      state.genreBooks = genreBooks;
      state.loanHistory = loanHistory;
      state.userInfo = userInfo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAndBooks.fulfilled, (state, action) => {
      const { status_code, description } = action.payload;
      if (!action.payload.response_body) {
        if (status_code === 9404) {
          state.failedLogin = String.ERROR.LOGIN.NOT_VAILD;
        } else if (status_code === 3406) {
          const SEARCH_WORD = 'after';
          const SEARCH_POSITION = description.search(SEARCH_WORD);
          const SEARCH_LENGTH = SEARCH_WORD.length;

          const blockTime = description.substring(
            SEARCH_POSITION + SEARCH_LENGTH
          );

          state.failedLogin = `${String.ERROR.LOGIN.RESTRICTED} \n ${blockTime} 이후 다시 시도해주세요.`;
        }
      } else {
        const { genreBooks, loanHistory, userInfo } =
          action.payload.response_body;

        state.genreBooks = genreBooks;
        state.loanHistory = loanHistory;
        state.userInfo = userInfo;

        window.sessionStorage.setItem(
          'data',
          JSON.stringify(action.payload.response_body)
        );
      }
    });
    builder.addCase(fetchUserAndBooks.rejected, (state, action) => {
      state.failedLogin = '통신이 불안정합니다. 잠시 후 다시 시도해주세요.';
      sessionStorage.clear();
    });
  },
});

export default personalRecommendBookSlice.reducer;

export const { resetUser, saveSessionValue } =
  personalRecommendBookSlice.actions;
export const selectPRBSlice = (state: PayloadType) => state[PRB];
