import React, { ReactNode } from "react";
import { useAppDispatch } from "../../../hooks";
import { resetBook } from "../../../slices/bookDetailSlice";

import "./backdrop.styled.css";

export default function BackDrop({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  return (
    <div
      onClick={(e) => {
        const { id } = e.target as HTMLDivElement;
        if (id === "backdrop") {
          dispatch(resetBook());
        }
      }}
      className="absolute w-screen h-screen "
      id="backdrop"
    >
      {children}
    </div>
  );
}
