import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

//redux
import { Provider } from "react-redux";
import { store } from "./store";

// router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// pageComponent
import Main from "./components/common/Main";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/LoginPage";
import RequireLogin from "./components/auth/RequireLogin";
import PersonalRecommendBookPage from "./pages/PersonalRecommendBookPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      { path: ":library/login", element: <LoginPage /> },
      {
        path: "NotFound",
        element: <ErrorPage />,
      },
      {
        path: ":library/personal-recommendation",
        element: (
          <RequireLogin>
            <PersonalRecommendBookPage />
          </RequireLogin>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
