import React, { useEffect, useState } from "react";
import PieGraph from "./PieGraph";
import PieGraphSmall from "./PieGraphSmall";
// import PieGraphDesktop from "./PieGraphDesktop";
// import PieGraphDesktop from "./PieGraphDeskTop";

import { LoanHistoryType } from "../../../types/Books";
import Strings from "../../../configs/Strings";

import classNames from "classnames";

export type graphLabel = {
  x: string;
  y: number;
  label: string;
};

const initData = [
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
  { x: "", y: 1, label: " " },
];

export default function TotalBookGraph({
  loanHistory,
}: {
  loanHistory: LoanHistoryType;
}) {
  const [graphData, setGraphData] = useState<graphLabel[]>(initData);
  const [currWidth, setCurrWidth] = useState(window.innerWidth);
  const qty = loanHistory.totalLoanQuantity;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setCurrWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setCurrWidth(window.innerWidth);
      });
    };
  }, [currWidth]);

  useEffect(() => {
    if (qty === 0 || qty === -1) return;

    const favoriteGenreData = loanHistory.favoriteGenre;
    const filteredData = Object.values(favoriteGenreData).map((el) => {
      const haveGenreHistory = el.percentage;
      const labelObj = {
        x: haveGenreHistory ? el.genre : " ",
        y: el.percentage,
        label: haveGenreHistory ? `${el.genre} ${el.percentage}%` : " ",
      };
      return labelObj;
    });
    setGraphData(filteredData);
  }, [loanHistory, qty]);

  return (
    <div className="relative ">
      {qty === 0 && (
        <div
          // className={classNames(
          //   "absolute text-center w-full text-3xl bottom-[15%]",
          //   "tablet:bottom-0", "mobile:text-2xl"
          // )}
          className={classNames(
            "absolute text-center w-full",
            "text-3xl desktop:top-[15%]",
            "qhd:text-5xl",
            "tablet:bottom-0", "mobile:text-2xl"
          )}
        >
          {Strings.PAGE.RECOMMEND.SENTENCE.NO_RESULT}
        </div>
      )}

      {currWidth > 820 ? (
        <PieGraphSmall data={graphData} loanQty={qty} />
      ) : (
        <PieGraph data={graphData} loanQty={qty} />
      )}
    </div>
  );
}
