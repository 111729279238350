// 중앙도서관 AK,
// 중앙도서관 수정분관 AH,
// 해운대도서관 BD,
// 사하도서관 AE
// 서동 도서관 추가 예정~

export type Manage_code = "AK" | "AH" | "BD" | "AE";
export const librarys = ["ak", "ah", "bd", "ae"];
export type Library_code = typeof librarys[number];

export type libraryParams = {
  library: Library_code;
};
