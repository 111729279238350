import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { libraryParams } from "../../types/Library";

import logo_busan_joongang_sujeong from "../../assets/images/logo/부산광역시중앙도서관_수정분관_web.png";
import logo_saha from "../../assets/images/logo/사하도서관_web.png";
import logo_busan_joongang from "../../assets/images/logo/부산광역시중앙도서관_web.png";
import logo_haeundae from "../../assets/images/logo/해운대도서관_web.png";

export default function Header() {
  const { library } = useParams() as libraryParams;
  const defaultLogoSize = 'w-56 qhd:w-80  tablet:w-48 mobile:w-32'
  const [logoImg, setLogoImg] = useState("");
  const [logoStyle, setLogoStyle] = useState(defaultLogoSize);

  useEffect(() => {
    switch (library) {
      case "ak":
        setLogoImg(logo_busan_joongang);
        break;
      case "ah":
        setLogoImg(logo_busan_joongang_sujeong);
        setLogoStyle(`${defaultLogoSize}  mobile:h-5`);
        break;
      case "ae":
        setLogoImg(logo_saha);
        break;
      case "bd":
        setLogoImg(logo_haeundae);
        setLogoStyle(`${defaultLogoSize}  mobile:h-5`);
        break;
      default:
        setLogoImg("");
        break;
    }
  }, [library]);

  return (
    <header className="flex items-center px-8 mobile:px-5">
      {logoImg && <img src={logoImg} alt={`로고`} className={logoStyle} />}
    </header>
  );
}
