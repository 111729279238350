import React from "react";
import Strings from "../../configs/Strings";
import classNames from "classnames";

const Footer = () => {
  return (
    <footer className={classNames("flex justify-center items-center", "text-sm mobile:text-xxs qhd:text-lg uhd:text-xl", "font-nanum")} >
      {Strings.FOOTER}
    </footer>
  );
};

export default Footer;
