import { History } from "history";
import { combineReducers } from "redux";
import bookDetailSliceReducer, { BOOK_DETAIL } from "./slices/bookDetailSlice";
import personalRecommendBookSliceReducer, {
  PRB,
} from "./slices/personalRecommendBookSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    [PRB]: personalRecommendBookSliceReducer,
    [BOOK_DETAIL]: bookDetailSliceReducer,
  });
}
