import classNames from 'classnames';
import React from 'react';
import { BookType } from '../../../types/Books';
import RecommendedBookItem from './RecommendedBookItem';

export default function RecommendedBookList({
  pickedGenreBooks,
}: {
  pickedGenreBooks?: BookType[];
}) {

  return (
    <ul
      className={classNames(
        'w-full flex flex-wrap justify-center desktop:w-10/12',
        'tablet:px-3',
        'mobile:my-3',
      )}
      style={{
        minHeight: '400px',
        height: 'auto',
      }}
    >
      {pickedGenreBooks?.map((book, index) => (
        <RecommendedBookItem
          img={book.imageUrl}
          title={book.title}
          spc_code={book.spc_code}
          key={book.spc_code}
          index={index}
        />
      ))}
    </ul>
  );
}
