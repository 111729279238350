import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectBookDetailSlice } from "../../slices/bookDetailSlice";

import Header from "./Header";
import Footer from "./Footer";

import { MainLayoutWrapper, PageWrapper } from "./ui/Wrappers";
import BackDrop from "./ui/BackDrop";
import BooKDetailModal from "../bookDetailModal/BooKDetailModal";
import useMatchUrl from "../../hooks/useMatchUrl";

const Main = () => {
  const { detailModalOpen } = useSelector(selectBookDetailSlice);
  useMatchUrl();

  return (
    <>
      {detailModalOpen && (
        <BackDrop>
          <BooKDetailModal />
        </BackDrop>
      )}

      <MainLayoutWrapper>
        <Header />
        <PageWrapper>
          <Outlet />
        </PageWrapper>
        <Footer />
      </MainLayoutWrapper>
    </>
  );
};

export default Main;
