import React, { useState, useEffect, useRef } from 'react';
import { fetchBookDetail } from '../../../slices/bookDetailSlice';
import { Manage_code } from '../../../types/Library';
import { useAppDispatch } from '../../../hooks';
import { v4 as uuidv4 } from "uuid";

import { useParams } from 'react-router-dom';
import { libraryParams } from '../../../types/Library';

import BookTitle from '../../common/ui/BookTitle'

import noBookImg from '../../../assets/images/book/noimages.png';

import './recommendedBook.styled.css';
import classNames from 'classnames';


export default function RecommendedBookItem({
  img,
  title,
  spc_code,
  index,
}: {
  img?: string;
  title?: string;
  spc_code?: string;
  index: number;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bookImg, setBookImg] = useState(img ? img : noBookImg);

  const dispatch = useAppDispatch();
  const { library } = useParams() as libraryParams;

  const imgRef = useRef<HTMLImageElement>(null); // 이미지
  const observer = useRef<IntersectionObserver>(); // IntersectionObserver 변수

  const titleArr = title && title.trim().substring(0, 15).split('');

  useEffect(() => {
    observer.current = new IntersectionObserver(intersectionOberserver); // 인스턴스 생성
    imgRef.current && observer.current.observe(imgRef.current); // 이미지 태그 관찰 시작
  }, []);

  const intersectionOberserver = (
    entries: IntersectionObserverEntry[],
    io: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 관찰되고 있는 entry가 보여지게 된 다면
        io.unobserve(entry.target); // 관찰 종료
        setIsLoading(false); // 로딩 체크
      }
    });
  };

  const onClick = () => {
    if (spc_code) {
      dispatch(
        fetchBookDetail({
          spcCode: spc_code,
          manage_code: library.toUpperCase() as Manage_code,
        })
      );
    }
  };

  return (
    <li
      className={classNames(
        'basis-1/5 h-52 qhd:h-64',
        'tablet:h-44',
        `${
          index > 3 ? 'mobile:-mx-3 mobile:basis-1/3' : 'mobile:basis-1/4 mobile:-mx-1'
        }`,
        ' mobile:mb-1'
      )}
    >
      <div className={classNames('flex flex-col items-center', 'mobile:h-40')}>
        <div
          className={classNames(
            'book-item cursor-pointer',
            'qhd:w-28 qhd:h-40 ',
            'w-24 h-32',
            'tablet:w-20 tablet:h-28',
            'mobile:w-16 mobile:h-24',
            'before:-right-1.5 after:w-1 after:-right-[1.3px]',
            'after:tablet:-right-0.5',
            'before:hover:w-0 after:hover:w-0 transition ease-in-out delay-200'
          )}
          onClick={onClick}
        >
          <img
            ref={imgRef}
            src={isLoading ? noBookImg : bookImg}
            alt={`${title} img`}
            className={classNames(
              'w-24 h-32',
              'qhd:w-28 qhd:h-40',
              'tablet:w-20 tablet:h-28  ',
              'mobile:w-16 mobile:h-24'
            )}
            loading="lazy"
          />
        </div>
        <p
          className={classNames(
            'text-sm mt-3 text-center cursor-pointer w-24 ',
            'mobile:text-xxs mobile:leading-4 mobile:w-16',
            'qhd:text-base ',
          )}
          onClick={onClick}
        >
          {titleArr &&
            titleArr.map((char) => {
              const isChinese = !char.match(
                /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|\s]/
              );
              return <BookTitle key={uuidv4()} isChinese={isChinese} char={char} />;
            })}
          {title && title.length > 15 && `...`}
        </p>
      </div>
    </li>
  );
}

export const bookTitle = () => {};
