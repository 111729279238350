import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Library_code, librarys, libraryParams } from "../types/Library";

export default function useMatchUrl() {
  const [isLibray, setIsLibrary] = useState<boolean>();

  const { library } = useParams() as libraryParams;

  const navigate = useNavigate();
  const checkLibrary = (x: any): x is Library_code => librarys.includes(x);

  useEffect(() => {
    const result = checkLibrary(library);
    if (!result) {
      navigate("NotFound");
    }
    setIsLibrary(result);
  }, []);

  return isLibray;
}
