import React from "react";

export default function SpecialCase({
  letter,
  extraStyle,
}: {
  letter: string;
  extraStyle?: string;
}) {
  return <span className={`font-serif ${extraStyle || ""}`}>{letter}</span>;
}
