import React, { SetStateAction } from "react";
import Strings from "../../../configs/Strings";
import { GenreType } from "../../../types/Books";
import { Dispatch } from "react";
import { RoundWrapper } from "../../common/ui/Wrappers";
import classNames from "classnames";

export default function GenreList({
  selectedGenre,
  setSelectedGenre,
}: {
  selectedGenre: GenreType;
  setSelectedGenre: Dispatch<SetStateAction<GenreType>>;
}) {
  const genreNames = Strings.BOOK.GENRE as GenreType[];

  return (
    <ul
      className={classNames("flex flex-wrap	justify-center ", " tablet:w-full ")}
    >
      {genreNames.map((genre_name) => (
        <li
          key={genre_name}
          className="m-0.5 mb-1.5 cursor-pointer "
          onClick={() => {
            setSelectedGenre(`${genre_name}`);
          }}
        >
          <RoundWrapper
            padding="py-0.5 px-2 tablet:px-2.5 qhd:px-4 qhd:text-xl qhd:mr-1 "
            parsedRgba={`${
              selectedGenre === genre_name
                ? "rgba(20,91,172,0.9)"
                : "rgba(255,255,255,0.3)"
            }`}
          >
            {genre_name}
          </RoundWrapper>
        </li>
      ))}
    </ul>
  );
}
