import React from 'react'

 const BookTitle = ({
  isChinese,
  char,
}: {
  isChinese: boolean;
  char: string | undefined;
}) => {
  if (isChinese) {
    return <span style={{fontFamily: 'umeboshi' }}>{char}</span>;
  }
  return <>{char}</>;
};
export default BookTitle