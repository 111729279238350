import React, { useEffect, useState } from "react";
import Strings from "../../../configs/Strings";

import GenreList from "./GenreList";
import { GenreBookType, GenreType } from "../../../types/Books";
import { BookType } from "../../../types/Books";
import RecommendedBookList from "./RecommendedBookList";
import { PrbComponentWrapper } from "../../common/ui/Wrappers";

import { HiLightBulb } from "react-icons/hi";
import classNames from "classnames";

export default function PersonalRecommendedBook({
  genreBooks,
}: {
  genreBooks: GenreBookType;
}) {
  const [selectedGenre, setSelectedGenre] = useState<GenreType>("총류");
  const [pickedGenreBooks, setPickedGenreBooks] = useState<BookType[]>();

  useEffect(() => {
    setPickedGenreBooks(genreBooks[`${selectedGenre}`]);
  }, [genreBooks, selectedGenre]);

  return (
    <PrbComponentWrapper extraStyle={"flex-col"}>
      <div className={classNames("flex items-center justify-center relative  mb-2 " ,"text-lg mobile:text-base", "qhd:text-2xl")}>
        <HiLightBulb
          className=" text-highlight-yellow mr-1 mb-2 text-3xl qhd:text-4xl"
          style={{
            left: "-17%",
            top: "-45%",
          }}
        />
        <span>{Strings.PAGE.RECOMMEND.SENTENCE.GUIDE}</span>
      </div>
      <GenreList
        setSelectedGenre={setSelectedGenre}
        selectedGenre={selectedGenre}
      />

      <RecommendedBookList pickedGenreBooks={pickedGenreBooks} />
    </PrbComponentWrapper>
  );
}
