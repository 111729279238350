import React, { ReactNode } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectBookDetailSlice } from "../../../slices/bookDetailSlice";

export function MainLayoutWrapper({ children }: { children: ReactNode }) {
  const { detailModalOpen } = useSelector(selectBookDetailSlice);

  return (
    <div
      className={classNames(
        "h-screen w-screen grid grid-rows-layout",
        `${detailModalOpen && "overflow-hidden"}`
      )}
    >
      {children}
    </div>
  );
}

export function PageWrapper({ children }: { children: ReactNode }) {
  return <main className="flex items-center justify-center">{children}</main>;
}

export function RoundWrapper({
  children,
  padding,
  parsedRgba,
}: {
  children: ReactNode;
  padding?: string;
  parsedRgba?: string;
}) {
  return (
    <div
      className={classNames(
        `flex items-center justify-center text-center w-fit rounded-full`,
        `${padding} `
      )}
      style={{
        background: `${parsedRgba ? parsedRgba : "rgba(255, 255, 255, 0.16)"}`,
      }}
    >
      {children}
    </div>
  );
}

export function PrbComponentWrapper({
  children,
  extraStyle,
}: {
  children: ReactNode;
  extraStyle?: string;
}) {
  return (
    <div
      className={classNames(
        "h-full w-full flex items-center justify-center",
        `${extraStyle}`
      )}
    >
      {children}
    </div>
  );
}

// todo 나중에 정리
export function gridTemplateWrapper({
  children,
  type,
  parsed,
}: {
  children: ReactNode;
  type: "rows" | "cols";
  parsed: string;
}) {}
