import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
import classNames from "classnames";

import { IoClose } from "react-icons/io5";
import { ImSearch } from "react-icons/im";
import { useSelector } from "react-redux";
import { resetBook, selectBookDetailSlice } from "../../slices/bookDetailSlice";

import noBookImg from "../../assets/images/book/noimages.png";
import BookTitle from "../common/ui/BookTitle";

import SpecialCase from "../common/ui/SpecialCase";
import Strings from "../../configs/Strings";

import { v4 as uuidv4 } from "uuid";


import "./bookDetail.styled.css";

export const BookDetailLine = ({
  listName,
  content,
}: {
  listName: string;
  content?: string;
}) => {
  return (
    <li className="my-0.5 mobile:m-0 text-xxs desktop:text-sm">
      <span className=" text-bookDetail-blue">
        <SpecialCase letter="●" extraStyle="mx-1" />
        {listName}
        <SpecialCase letter="|" extraStyle="mx-1" />
      </span>
      <span className="text-bookDetail-content ">{content}</span>
    </li>
  );
};

export default function BooKDetailModal() {
  const dispatch = useAppDispatch();
  const { book } = useSelector(selectBookDetailSlice);

  useEffect(() => {}, [book]);

  const titleArr = book.title && book.title.trim().split('');


  return (
    <section
      className="flex flex-col w-full h-full text-xs items-center justify-center "
      id="backdrop"
    >
      <div
        className={classNames(
          "flex justify-end px-2 ",
          "w-[540px] tablet:w-[480px] mobile:w-11/12",
          "desktop:w-[620px]"
        )}
      >
      <IoClose
          className=" text-white text-5xl cursor-pointer"
          onClick={() => {
            dispatch(resetBook());
          }}
        />
      </div>

      <div
        className={classNames(
          "h-auto min-h-[450px] desktop:min-h-[600px]",
          "p-8 rounded-3xl bg-white flex flex-col justify-around" ,
          "w-[540px] tablet:w-[480px]",
          "desktop:w-[620px]",
          "mobile:w-11/12 mobile:p-4 "
        )}
      >
        <div
          className="grid grid-cols-2 items-center"
          style={{ gridTemplateColumns: "40% auto" }}
        >
          <div
            className={classNames(
              "book-detail-shadow w-36 h-52r",
              "mobile:w-28 mobile:h-36 ",
              "desktop:w-48 desktop:h-64 "
            )}
          >
            <img
              src={book.imageUrl || noBookImg}
              alt={`${book.title} 이미지`}
              className={"w-full h-full"}
            />
          </div>

          <div>
            <h1 className=" text-gray-700 desktop:text-2xl text-xl tablet:text-lg mobile:text-base ">
              {titleArr &&
            titleArr.map((char) => {
              const isChinese = !char.match(
                /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|\s]/
              );
              return <BookTitle isChinese={isChinese} char={char} key={uuidv4()}/>;
            })}
            </h1>
            <div className="w-full border border-gray-300 my-3 mobile:my-2" />
            <ul>
              <BookDetailLine listName="저자" content={book.author} />
              <BookDetailLine
                listName="발행년도"
                content={book.publication_year}
              />
              <BookDetailLine listName="출판사" content={book.publisher} />
              <BookDetailLine
                listName="대출가능유무"
                content={book.book_status === "Y" ? "대출가능" : "대출불가"}
              />
              <BookDetailLine listName="페이지" content={book.pages} />
              <BookDetailLine listName="청구기호" content={book.call_no} />
              <BookDetailLine listName="ISBN" content={book.isbn13} />
              <BookDetailLine
                listName="소장위치"
                content={book.shelf_loc_name}
              />
            </ul>
          </div>
        </div>

        <div>
          <fieldset
            className={classNames(
              "min-h-[150px] max-h-[150px]",
              "desktop:min-h-[200px] desktop:max-h-[200px]",
              "text-bookDetail-blue border-2	 border-bookDetail-blue rounded-xl overflow-auto pl-3"
            )}
          >
            <legend className="pl-0.5 pr-2 ml-5 text-bookDetail-blue  desktop:mx-7">
              <div className="flex  py-1 items-center text-base mobile:text-sm desktop:text-xl ">
                <ImSearch className="mx-1 text-lg	 mr-2 mobile:text-sm mobile:mr-1 desktop:text-2xl" />
                {Strings.BOOK.DETAIL.DESCRIPTION}
              </div>
            </legend>
            <div className={classNames("px-2 py-1 desktop:px-5 desktop:py-1.5  mobile:px-1", 
            "text-bookDetail-content" ,
            "text-xxs desktop:text-sm",
            "description")}>
              {book.description}
            </div>
          </fieldset>
        </div>
      </div>
    </section>
  );
}
