import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks";
import classNames from "classnames";

import {
  selectPRBSlice,
  saveSessionValue,
} from "../slices/personalRecommendBookSlice";
import { selectBookDetailSlice } from "../slices/bookDetailSlice";

import TotalBookGraph from "../components/personelRecommendBook/graph/TotalBookGraph";
import PersonalRecommendedBook from "../components/personelRecommendBook/recommendation/PersonalRecommendedBook";

import Strings from "../configs/Strings";
import { RoundWrapper } from "../components/common/ui/Wrappers";

import prbDividerImg from "../assets/images/borders/line.png";

export const PrbPageDivder = () => {
  return (
    <div className="flex items-center mr-10 tablet:hidden">
      <img src={prbDividerImg} className="h-4/6	 " alt="img" />
    </div>
  );
};

const PersonalRecommendBookPage = () => {
  const { userInfo, genreBooks, loanHistory } = useSelector(selectPRBSlice);
  const { detailModalOpen } = useSelector(selectBookDetailSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const parsedData = window.sessionStorage.getItem("data");
    const dataObj = parsedData && JSON.parse(parsedData);
    dispatch(saveSessionValue(dataObj));
  }, []);


  return (
    <section
      className={classNames(
        "h-full w-11/12 flex flex-col items-center justify-evenly overflow-hidden",
        "mobiie:pt-3 tablet:pt-5",
        `tablet:overflow-visible ${detailModalOpen && "overflow-hidden"}`
      )}
    >
      {/* name */}
      <div className=" w-full flex justify-center ">
        <RoundWrapper padding="px-6 py-3 tablet:py-1.5 tablet:px-5 mobile:py-1 qhd:px-8 qhd:py-5 ">
          <h1 className="qhd:text-5xl text-4xl  tablet:text-2xl mobile:text-xl ">
            <span className="text-highlight-cyan">{userInfo.userName}</span>
            {Strings.PAGE.RECOMMEND.SENTENCE.RESULT}
          </h1>
        </RoundWrapper>
      </div>

      <div
        className={classNames(
          "max-h-[650px]",
          "w-full flex justify-center items-center",
          "tablet:flex-col tablet:gap-10 tablet:max-h-max	",
          "mobile:gap-5"
        )}
      >
        <div className={classNames(" w-1/2 ", "tablet:w-full", "mobile:mb-5")}>
          <TotalBookGraph loanHistory={loanHistory} />
        </div>
        <PrbPageDivder />
        <div
          className={classNames(
            "prb-wrapper qhd:max-h-[800px]  w-1/2 max-h-[500px] overflow-y-auto",
            "tablet:w-full tablet:max-h-max tablet:overflow-visible "
          )}
        >
          <PersonalRecommendedBook genreBooks={genreBooks} />
        </div>
      </div>
    </section>
  );
};

export default PersonalRecommendBookPage;
