import { configureStore } from "@reduxjs/toolkit";
import { createMemoryHistory } from "history";
import { createLogger } from "redux-logger";
import createRootReducer from "./rootReducer";

export const history = createMemoryHistory();
const rootReducer = createRootReducer(history);


const logger = createLogger({ level: "info", collapsed: true });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if(process.env.NODE_ENV === `development`){
      return getDefaultMiddleware().concat(logger) 
    }
    return getDefaultMiddleware()  
  },
  
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
