import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BookType } from "../types/Books";

import { getBookDetail } from "../network/book.api";

export const BOOK_DETAIL = "bookDetailSlice";

type BookDetailTypes = {
  book: BookType;
  detailModalOpen: boolean;
};

type PayloadType = {
  [BOOK_DETAIL]: BookDetailTypes;
};

export const fetchBookDetail = createAsyncThunk(
  `${BOOK_DETAIL}/fetchBookDetail`,
  getBookDetail
);

const bookDetailSlice = createSlice({
  name: `${BOOK_DETAIL}`,
  initialState: {
    book: {},
    detailModalOpen: false,
  } as BookDetailTypes,
  reducers: {
    resetBook: (state) => {
      state.book = {};
      state.detailModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookDetail.fulfilled, (state, action) => {
      state.book = action.payload.response_body;
      state.detailModalOpen = true;
    });
    builder.addCase(fetchBookDetail.rejected, (state, action) => {
      console.log("error!!!!!!!!");
    });
  },
});

export const { resetBook } = bookDetailSlice.actions;
export default bookDetailSlice.reducer;
export const selectBookDetailSlice = (state: PayloadType) => state[BOOK_DETAIL];
