import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import PersonalRecommendBookPage from "../../pages/PersonalRecommendBookPage";

import { useParams } from "react-router-dom";
import { libraryParams } from "../../types/Library";



export default function RequireLogin({ children }: { children: ReactNode }) {

  const { library } = useParams() as libraryParams;
  
  
  const parsedLibrary = window.sessionStorage.getItem("library");
  const savedLibrary = parsedLibrary && JSON.parse(parsedLibrary);
  
  if (savedLibrary !== library) {
    return <Navigate to={`/${library}/login`} />;
  }
  return <PersonalRecommendBookPage />;
}
