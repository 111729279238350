import axios from "./axios";
import { BookType } from "../types/Books";
import { ResponseVo } from "../types/Response";
import { BookDetailParamType } from "../types/Books";

export const getBookDetail = async (bookDetailParam: BookDetailParamType) => {
  const response = await axios.get<ResponseVo<BookType>>(
    "/bookV2/searchForSpcCode",
    { params: bookDetailParam }
  );
  return response.data;
};
