import React from "react";
import { useEffect } from "react";
import { useRouteError, useLocation } from "react-router-dom";
import { BiError } from "react-icons/bi";

import classNames from "classnames";

export default function ErrorPage() {
  const {pathname}  = useLocation();

  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const error = useRouteError();
  console.error(error);

  const screenSize = pathname === '/NotFound' ? 'full': 'screen'

  return (
    <section className={classNames(`w-${screenSize} h-${screenSize}`, "flex items-center justify-center ")}>
      <div className=" text-center flex flex-col items-center justify-center w-11/12 ">
        <BiError className="icon-err text-9xl mb-8 text-highlight-yellow mobile:text-7xl mobile:mb-3 " />
        <h1 className="text-4xl mb-5 mobile:mb-3 mobile:text-2xl ">
          요청하신 페이지를 찾을 수 없습니다.
        </h1>

        <div className="text-2xl mobile:text-sm">
          <p>페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.</p>
          <p>입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</p>
        </div>
      </div>
    </section>
  );
}
