import React, { useEffect, useState, SetStateAction } from "react";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { SHA256 } from "crypto-js";
import { Dispatch } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  selectPRBSlice,
  fetchUserAndBooks,
  resetUser,
} from "../../slices/personalRecommendBookSlice";

import Strings from "../../configs/Strings";
import { User } from "../../types/User";

import { BiUser } from "react-icons/bi";
import { MdOutlineLock } from "react-icons/md";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { libraryParams } from "../../types/Library";

import "./login.styled.css";
import classNames from "classnames";

export function LoginForm({
  setLoading,
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const { failedLogin, userInfo } = useSelector(selectPRBSlice);
  const { library } = useParams() as libraryParams;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [errMsg, setErrMsg] = useState("");
  const [parsedUserInfo, setParsedUserInfo] = useState<User>({
    user_id: "",
    password: "",
  });

  useEffect(() => {}, [errMsg]);

  useEffect(() => {
    dispatch(resetUser());
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [parsedUserInfo]);

  useEffect(() => {
    const { userName, age } = userInfo;
    setLoading(false);
    if (userName && age !== -1 && !failedLogin) {
      navigate(`/${library}/personal-recommendation`);
    }
  }, [failedLogin, library, navigate, setLoading, userInfo]);

  useEffect(() => {
    if (failedLogin) {
      setErrMsg(failedLogin);
      dispatch(resetUser());
    }
  }, [dispatch, failedLogin]);

  const makeShaPwd = (pwd: string) => {
    const shaPwd = SHA256(pwd).toString();
    return shaPwd;
  };

  const validateLogin = (parsedUserInfo: User) => {
    const { user_id, password } = parsedUserInfo;

    if (!user_id || !password) {
      const stringKey = !user_id ? "MISSING_ID" : "MISSING_PWD";
      setErrMsg(Strings.ERROR.LOGIN[`${stringKey}`]);
      return false;
    }

    const vaildatedUser: User = {
      user_id: user_id.trim(),
      password: makeShaPwd(password),
      manage_code: library,
    };
    return vaildatedUser;
  };

  const onLoginBtnHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userInfo = validateLogin(parsedUserInfo);
    if (!userInfo) return;
    dispatch(fetchUserAndBooks(userInfo));
    setParsedUserInfo({ user_id: "", password: "" });
    setLoading(true);
  };

  const onLoginInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParsedUserInfo({ ...parsedUserInfo, [name]: value });
  };

  return (
    <form
      className="my-5 w-full px-3 relative mobile:px-0 mobile:mt-3 uhd:my-10"
      onSubmit={onLoginBtnHandler}
      id="loginform"
    >
      <div className="relative">
        <input
          className={classNames(
            "login-input",
            "rounded-full w-full focus:outline-none bg-loginInput-blue",
            "h-12 mb-3 p-3 pl-16",
            "qhd:h-14 qhd:text-xl ",
            "uhd:h-20 uhd:pl-24 uhd:text-2xl",
            "tablet:h-11 tablet:pl-14",
            "mobile:h-9 mobile:text-xs  mobile:pl-14"
          )}
          placeholder="아이디"
          name="user_id"
          value={parsedUserInfo.user_id}
          onChange={onLoginInputHandler}
          autoComplete="off"
          type="text"
        />
        <BiUser
          className={classNames(
            "absolute top-2 left-5 text-3xl",
            "uhd:text-5xl uhd:left-8",
            "qhd:top-3.5",
            "tablet:text-2xl tablet:top-3",
            "mobile:text-xl mobile:top-2"
          )}
        />
      </div>
      <div className="relative">
        <input
          className={classNames(
            "login-input",
            "font-serif tracking-widest",
            "rounded-full w-full focus:outline-none bg-loginInput-blue",
            "h-12 mb-3 p-3 pl-16",
            "uhd:h-20 uhd:pl-24 uhd:text-2xl",
            "qhd:h-14 qhd:text-xl",
            "tablet:h-11 tablet:pl-14",
            "mobile:h-9 mobile:text-xs  mobile:pl-14 mobile:mb-0"
          )}
          placeholder="패스워드"
          name="password"
          value={parsedUserInfo.password}
          onChange={onLoginInputHandler}
          autoComplete="off"
          type="password"
        />

        <MdOutlineLock
          className={classNames(
            "absolute top-2 left-5 text-3xl",
            "uhd:text-5xl uhd:left-8",
            "qhd:top-3.5",
            "tablet:text-2xl tablet:top-3",
            "mobile:text-xl mobile:top-2"
          )}
        />
      </div>


      {errMsg && (
        <div
          className={classNames(
            "relative mt-1 text-center w-[475px] -left-[28%] ",
            "uhd:w-[760px] uhd:-left-[25%]",
            "qhd:w-[580px] qhd:top-2",
            "tablet:text-xs tablet:w-[380px] tablet:right-[39%] ",
            "mobile:w-[285px] mobile:-left-[21%] mobile:top-2 ",
          )}
        >
          {errMsg.search("\n") === -1
            ? errMsg
            : errMsg.split("\n").map((msg) => <div key={uuidv4()} >{msg}</div>)}
        </div>
      )}
    </form>
  );
}
