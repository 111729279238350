const Strings = {
  FOOTER: "Individual Personalized Recommended Books",
  BOOK: {
    DETAIL: {
      AUTHOR: "저자",
      PUBLICATION_YEAR: "발행년도",
      PUBLISHER: "출판사",
      BOOK_STATUS: "대출가능유무",
      PAGES: "페이지",
      GENRE_NM: "장르",
      CALL_NO: "청구기호",
      ISBN: "ISBN",
      SHELF_LOC_NAME: "소장위치",
      DESCRIPTION: "요약정보",
      LIB_CMT: "사서의 한마디",
      REG_NO: "등록번호",
    },
    GENRE: [
      "총류",
      "철학",
      "종교",
      "사회과학",
      "자연과학",
      "기술과학",
      "예술",
      "언어",
      "문학",
      "역사",
    ],
  },
  PAGE: {
    RECOMMEND: {
      SENTENCE: {
        RESULT: " 님의 대여 이력 분석 결과",
        NO_RESULT: "대여 이력이 없습니다.",
        GUIDE: "장르를 선택하여 추천도서를 살펴보세요",
      },
      LOAN_QTY: "총 독서량",
      LOADING: "분석 중",
    },
    LOGIN: {
      TITLE: "개인 맞춤 추천 도서",
      ID: "아이디",
      PWD: "패스워드",
      LOGIN: "로그인",
    },
  },
  ERROR: {
    LOGIN: {
      NOT_VAILD:
        "아이디 또는 비밀번호를 잘못 입력했습니다.\n 입력하신 내용을 확인해주세요.",
      // RESTRICTED:
      //   "3회 이상 아이디 또는 비밀번호를 잘못 입력했습니다.\n 5분간 로그인이 제한됩니다.",
      RESTRICTED: "3회 이상 아이디 또는 비밀번호를 잘못 입력했습니다.\n",
      MISSING_ID: "아이디를 입력해주세요",
      MISSING_PWD: "비밀번호를 입력해주세요.",
    },
  },
};

export default Strings;
